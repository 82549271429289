#root {
  font-family: Roboto, sans-serif;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}
body {
  overflow: hidden;
  background-color: rgb(12, 7, 26);
}
