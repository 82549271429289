$primary: #161c3f;
$secondary: #b49673;
$dark: #141916;
$light: #c9b6a1;
$success: #266941;
$info: #141916;
$warning: #bb7924;
$danger: #6d231e;

@import "~bootstrap/scss/bootstrap.scss";
